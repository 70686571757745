import React from "react";

import { Link as GLink } from "gatsby";

const Link = ({ to = '', children, ...props }) => {
  const reg = new RegExp('^(?:[a-z]+:)?//', 'i');

  return reg.test(to) ?
    <a href={to} {...props}>{children}</a> :
    <GLink to={to} {...props}>{children}</GLink>
}

export default Link;
