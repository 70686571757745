import { graphql, useStaticQuery } from 'gatsby';

const useOptions = () => {
  const {
    allWp: { nodes },
  } = useStaticQuery(graphql`
    query getOptions {
      allWp {
        nodes {
          inActionForm {
            inActionForm {
              inActionFormTitle
              inActionFormSubtitle
              inActionFormPhoneInputLabel
              inActionFormNameInputLabel
              inActionFormLastNameInputLabel
              inActionFormCompanyNameInputLabel
              inActionFormEmailInputLabel
              inActionFormButton
              inActionFormAdditionalTitle
              inActionFormAdditionalCheckboxes {
                inActionFormAdditionalCheckboxesItem
              }
              inActionFormSuccess {
                inActionFormSuccessTitle
                inActionFormSuccessSubtitle
              }
              inActionFormTextareaComment
            }
          }
          letsTalkForm {
            letsTalkForm {
              letsTalkFormTitle
              letsTalkFormSubmitButton
              letsTalkFormPhoneInputLabel
              letsTalkFormNameInputLabel
              letsTalkFormLastNameInputLabel
              letsTalkFormCompanyNameInputLabel
              letsTalkFormEmailInputLabel
              letsTalkFormCommentInputPlaceholder
              letsTalkFormCommentInputLabel
              letsTalkFormSuccess {
                letsTalkFormSuccessSubtitle
                letsTalkFormSuccessTitle
              }
              letsTalkFormMainContent {
                letsTalkFormMainContentTitle
                letsTalkFormMainContentSubtitle
              }
            }
          }
          subscribeForm {
            subscribeForm {
              subscribeFormTitle
              subscribeFormInputLabel
              subscribeFormInputPlaceholder
              subscribeFormButtonTitle
              subscribeFormSuccessMessage {
                subscribeFormSuccessMessageTitle
                subscribeFormSuccessMessageSubtitle
              }
            }
          }
          header {
            header {
              requestButton {
                title
                url
              }
              loginButton {
                title
                url
              }
              headerNavigation {
                headerLinkOrDropdownSelect
                headerNavigationDropdown {
                  headerNavigationDropdownTitle
                  headerNavigationDropdownGroup {
                    headerNavigationDropdownGroupTitle
                    headerNavigationDropdownGroupLinks {
                      headerNavigationDropdownGroupLinksDescription
                      headerNavigationDropdownGroupLinksColor
                      headerNavigationDropdownGroupLinksIcon {
                        altText
                        sourceUrl
                      }
                      headerNavigationDropdownGroupLinksPage {
                        target
                        title
                        url
                      }
                    }
                  }
                }
                headerNavigationLink {
                  target
                  title
                  url
                }
              }
            }
          }
          footer {
            footer {
              aboutText
              copyrightText
              noteText
              socials {
                link
                icon {
                  altText
                  title
                  srcSet
                  sourceUrl
                }
              }
              logoLink {
                title
                url
              }
              logo {
                altText
                title
                srcSet
                sourceUrl
              }
            }
          }
          cookies {
            cookies {
              cookiesText
              cookiesDeclineButtonText
              cookiesAcceptButtonText
            }
          }
          page404 {
            notFoundPage {
              notFoundPageTitle
              notFoundPageSubtitle
              notFoundPageButton {
                title
                url
              }
            }
          }
          resourceCenterPostInfo {
            resourceCenterPostInfo {
              resourceCenterPostInfoTrial {
                resourceCenterPostInfoTrialTitle
                resourceCenterPostInfoTrialMarketedText
                resourceCenterPostInfoTrialUrl
              }
              resourceCenterPostInfoRating {
                resourceCenterPostInfoRatingTitle
                resourceCenterPostInfoRatingEvaluationText
              }
              resourceCenterPostInfoShare {
                resourceCenterPostInfoShareTitle
              }
            }
          }
          subscribeFormFull {
            subscribeFormFull {
              subscribeFormFullInputLabel
              subscribeFormFullTextInButton
              subscribeFormFullCheckboxField
              subscribeFormFullAdditionalText
              subscribeFormFullSuccessState
            }
          }
        }
      }
    }
  `);

  return nodes[0];
};

export default useOptions;
