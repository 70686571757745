import React from 'react';
import { Link } from 'gatsby';

import convertToSlug from 'helpers/stringToSlug';

import { stylesNavLink, textInner } from './styles.module.scss';

const NavLink = ({ url, navLink }) => {
  return (
    <Link
      id={`link-mob-nav-to-${convertToSlug(
        navLink?.headerNavigationDropdownGroupLinksPage?.title
      )}`}
      to={url}
      className={stylesNavLink}
      style={{
        '--page-bg-color': navLink?.headerNavigationDropdownGroupLinksColor,
      }}
    >
      <img
        src={navLink?.headerNavigationDropdownGroupLinksIcon?.sourceUrl}
        alt={navLink?.headerNavigationDropdownGroupLinksIcon?.altText}
        width={48}
        height={48}
      />
      <span className={textInner}>
        <span>{navLink?.headerNavigationDropdownGroupLinksPage?.title}</span>
        <br />
        <span>{navLink?.headerNavigationDropdownGroupLinksDescription}</span>
      </span>
    </Link>
  );
};

export default NavLink;
