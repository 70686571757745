import React, { useEffect, useState, useCallback } from 'react';
import classnames from 'classnames';
import isWebpSupported from 'supports-webp';

import useIsMounted from 'react-is-mounted-hook';
import { CSSTransition } from 'react-transition-group';

import Button from 'components/UI/Button';
import CookiesImg from 'images/common/cookies.png';
import CookiesImgWebp from 'images/common/cookies.webp';

import { getAnswer, setConfirmed, setDecline } from 'helpers/storage';
import useOptions from 'hooks/graphql/useOptions';

import * as styles from './cookies.module.scss';

let timer = null;

const CookiePopup = () => {
  const {
    cookies: { cookies },
  } = useOptions();

  const [isHidden, setHidden] = useState(true);
  const isMounted = useIsMounted();

  const handleGetConfirmedStatus = useCallback(() => {
    timer = setTimeout(() => {
      isMounted && setHidden(getAnswer());
    }, 1000);
  }, [isMounted]);

  useEffect(() => {
    handleGetConfirmedStatus();

    return () => clearTimeout(timer);
  }, [handleGetConfirmedStatus]);

  const handleConfirmed = useCallback(() => {
    setConfirmed();
    setHidden(true);
  }, []);

  const handleDecline = useCallback(() => {
    setHidden(true);
    setDecline();

    // document.cookie.split('; ').forEach((el) => {
    //   const key = el.split('=')[0];

    //   if (!['_ga', '_gid'].includes(key)) return false;

    //   document.cookie = key + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/';
    // });

    // delete window.ga;
  }, []);

  return (
    <CSSTransition
      in={!isHidden}
      classNames="scr-css-transition--slide-up"
      timeout={{
        enter: 300,
        exit: 100,
      }}
      unmountOnExit
    >
      <div className={classnames(styles.cookies)}>
        <div className="scr-wrap">
          <div className={classnames(styles.inner)}>
            <div className={classnames(styles.content)}>
              <img
                className={classnames(styles.img)}
                src={isWebpSupported ? CookiesImgWebp : CookiesImg}
                alt="cookies"
                loading="lazy"
                width="100%"
                height="100%"
              />

              <div
                className={classnames(styles.text)}
                dangerouslySetInnerHTML={{ __html: cookies.cookiesText }}
              ></div>
            </div>

            <div className={classnames(styles.btns)}>
              <Button
                onClick={handleConfirmed}
                className="scr-btn scr-btn--small"
                id="button-cookie-accept"
              >
                {cookies.cookiesAcceptButtonText}
              </Button>

              <Button
                onClick={handleDecline}
                className="scr-btn scr-btn--small scr-btn--light"
                id="button-cookie-decline"
              >
                {cookies.cookiesDeclineButtonText}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default CookiePopup;
