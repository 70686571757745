import React from 'react';
import classnames from 'classnames';
import Link from '@/components/Link';

import MenuDropdown from '../MenuDropdown';

import useOptions from 'hooks/graphql/useOptions';
import convertToSlug from 'helpers/stringToSlug';

import * as styles from './menu.module.scss';

const Menu = ({ className }) => {
  const {
    header: {
      header: { headerNavigation },
    },
  } = useOptions();

  return (
    <nav>
      <ul className={classnames(styles.menu, className)}>
        {headerNavigation &&
          headerNavigation.map((col, index) => {
            const url = process.env.GATSBY_DEV
              ? col?.headerNavigationLink?.url.replace(
                  'https://screver.com',
                  ''
                )
              : col?.headerNavigationLink?.url;

            return (
              <li key={`col-${index}`}>
                <div key={`item-${index}`}>
                  {col.headerLinkOrDropdownSelect === 'link' ? (
                    <Link
                      data-link
                      className={classnames(styles.link)}
                      to={url}
                      id={`link-header-to-${convertToSlug(
                        col.headerNavigationLink.title
                      )}`}
                    >
                      {col.headerNavigationLink.title}
                    </Link>
                  ) : (
                    <MenuDropdown groupList={col.headerNavigationDropdown} />
                    // <>dropdown</>
                  )}
                </div>
              </li>
            );
          })}
      </ul>
    </nav>
  );
};

export default Menu;
