import React from 'react';
import classnames from 'classnames';
// import { Link } from "gatsby";
import Link from '@/components/Link';

import useMenu from 'hooks/graphql/useMenu';

import * as styles from './menu.module.scss';

import convertToSlug from 'helpers/stringToSlug';

const Menu = () => {
  const menus = useMenu();

  return (
    <>
      {menus &&
        menus.map((col, index) => {
          return (
            <ul key={`col-${index}`} className={styles.col}>
              <li key={`item-${index}`}>
                <Link
                  className={classnames(styles.col_link, {
                    [styles.disabel]: col.path === '#',
                  })}
                  to={col.path}
                  id={`link-footer-to-${convertToSlug(col.label)}`}
                >
                  {col.label}
                </Link>

                <ul className={styles.col_list}>
                  {col.children.map((li, i) => {
                    return (
                      <li key={`li-${i}`}>
                        <Link
                          className={classnames({
                            [styles.soon]:
                              li.path === '/' && li.connectedNode === null,
                          })}
                          to={li.path}
                          id={`link-footer-to-${convertToSlug(li.label)}`}
                        >
                          {li.label}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </li>
            </ul>
          );
        })}
    </>
  );
};

export default Menu;
