import React from 'react';
import classnames from 'classnames';

import * as styles from './burger-button.module.scss';

const BurgerButton = ({ open, onClick }) => {
  return (
    <div
      className={classnames(styles.btn, {
        [styles.open]: open,
      })}
      onClick={(e) => onClick(e)}
      id="button-header-mob-burger"
    >
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default BurgerButton;
