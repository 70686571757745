import React, { useCallback, useState, useEffect } from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import NavLink from '../Link';

import * as styles from './menu-dropdown.module.scss';
import Icon from 'components/UI/Icon';
import ClickOutside from 'components/wrappers/ClickOutside';
import convertToSlug from 'helpers/stringToSlug';

let timer = null;

const MenuDropdown = ({ groupList }) => {
  const [open, setOpen] = useState(false);

  const outside = useCallback(() => {
    timer = setTimeout(() => {
      setOpen(false);
    }, 50);
  }, []);

  useEffect(() => {
    return () => clearTimeout(timer);
  }, []);

  return (
    <div
      className={classnames(styles.wrap, {
        [styles.open]: open,
      })}
    >
      <ClickOutside onClick={outside}>
        <>
          {groupList.map((group) => {
            return (
              <p
                key={group?.headerNavigationDropdownTitle}
                data-button
                className={styles.btn}
                onClick={(e) => setOpen(!open)}
                id={`button-header-nav-${convertToSlug(
                  group?.headerNavigationDropdownTitle
                )}`}
              >
                <span>{group?.headerNavigationDropdownTitle}</span>
                <Icon name="arrow-bottom" />
              </p>
            );
          })}
        </>
      </ClickOutside>

      <CSSTransition
        in={open}
        classNames="scr-css-transition--slide-up"
        timeout={{
          enter: 50,
          exit: 100,
        }}
        unmountOnExit
      >
        {/* TODO: old */}
        {/* <ul className={styles.list}>
          {item.headerNavigationDropdownGroup.map((li, i) => (
            <li key={`li-${i}`}>
              <Link
                className={classnames(
                  // {
                  //   [styles.soon]: li.connectedNode == null,
                  // },
                  'scr-link'
                )}
                to={li.path}
                id={`link-header-to-${convertToSlug(li.label)}`}
              >
                {li.label}
              </Link>
            </li>
          ))}
        </ul> */}
        <div className={styles.list}>
          {groupList?.map((groupItem, groupItemIdx) => {
            return (
              <div
                className={styles.groups}
                key={
                  groupItem.headerNavigationDropdownGroup[groupItemIdx]
                    .headerNavigationDropdownGroupTitle
                }
              >
                {groupItem.headerNavigationDropdownGroup.map(
                  (item, itemIdx) => {
                    return (
                      <div
                        className={styles.groupItem}
                        key={itemIdx}
                        style={{
                          pointerEvents:
                            item?.headerNavigationDropdownGroupTitle ===
                              'Pharma and Healthcare' && 'none',
                        }}
                      >
                        <p className={styles.groupItem_title}>
                          {item?.headerNavigationDropdownGroupTitle}
                        </p>

                        <ul className={styles.pagesList}>
                          {item?.headerNavigationDropdownGroupLinks?.map(
                            (page, pageIdx) => {
                              const url = process.env.GATSBY_DEV
                                ? page?.headerNavigationDropdownGroupLinksPage?.url.replace(
                                    'https://screver.com',
                                    ''
                                  )
                                : page?.headerNavigationDropdownGroupLinksPage
                                    ?.url;

                              return (
                                <li key={pageIdx}>
                                  <NavLink url={url} navLink={page}></NavLink>
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    );
                  }
                )}
              </div>
            );
          })}
        </div>
      </CSSTransition>
    </div>
  );
};

export default MenuDropdown;
