import React, { useEffect } from 'react';
import classnames from 'classnames';
import { useLocation } from '@reach/router';

import { clearAllBodyScrollLocks } from 'body-scroll-lock';
import * as RDD from 'react-device-detect';

import SEO from '@/sections/Layout/SEO';
import CookiePopup from 'sections/Layout/CookiePopup';
import Header from '@/sections/Layout/Header';
import Footer from '@/sections/Layout/Footer';
// import StretchBanner from 'sections/Layout/StretchBanner/index';

import 'styles/styles.scss';

// Goggle fonts loading
import '@fontsource/poppins';
import '@fontsource/poppins/300.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';

const Layout = ({
  children,
  seo,
  headerDarkColor = false,
  headerWhiteBg = false,
  hideRequestButton = false,
  requestButtonBlue = false,
  ogImage = null,
}) => {
  const location = useLocation();

  // clean all scroll locks on page
  useEffect(() => {
    clearAllBodyScrollLocks();
  }, [location.href]);

  // add class for custom scroll if device are desktop & not IOS, MacOs
  useEffect(() => {
    const htmlEl = document.documentElement;
    const bodyEl = document.body;

    RDD.isDesktop &&
      !RDD.isIOS &&
      !RDD.isMacOs &&
      htmlEl.classList.add('scr-custom-scroll') &&
      bodyEl.classList.add('scr-custom-scroll');
  }, []);

  return (
    <>
      <SEO seo={seo} ogImage={ogImage} />
      <div className={classnames('scr-page-wrapper')}>
        {/* <StretchBanner /> */}
        <Header
          headerDarkColor={headerDarkColor}
          headerWhiteBg={headerWhiteBg}
          hideRequestButton={hideRequestButton}
          requestButtonBlue={requestButtonBlue}
        />

        <main>{children()}</main>

        <CookiePopup />
        <Footer />
      </div>
    </>
  );
};

export default Layout;
