// extracted by mini-css-extract-plugin
export var footer = "footer-module--footer--20WK3";
export var inner = "footer-module--inner--17EUW";
export var left = "footer-module--left--_XUOY";
export var left_logo = "footer-module--left_logo--lSBSc";
export var left_text = "footer-module--left_text--2S-TD";
export var social = "footer-module--social--2aoVF";
export var social_list = "footer-module--social_list--2d4a8";
export var note = "footer-module--note--RW6Js";
export var note_copy = "footer-module--note_copy--5HohV";
export var note_text = "footer-module--note_text--2PvLA";
export var listWrap = "footer-module--listWrap--uwywp";