import React, { useEffect, useState } from 'react';
import classnames from 'classnames';

import Link from '@/components/Link';

import * as styles from './header.module.scss';
import useScroll from 'hooks/useScroll';
import useDeviceState from 'hooks/useDeviceState';
import { DESKTOP_KEY } from 'constants/devices.js';
import Menu from './components/Menu';
import MobileMenu from './components/MobileMenu';
import Icon from 'components/UI/Icon';

import useOptions from 'hooks/graphql/useOptions';

const Header = ({
  headerDarkColor,
  headerWhiteBg,
  hideRequestButton,
  requestButtonBlue,
}) => {
  const {
    header: { header },
  } = useOptions();

  const { scrollY } = useScroll();
  const deviceState = useDeviceState();
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    setScrolled(scrollY > 30);
  }, [scrollY]);

  return (
    <header className={classnames(styles.header)}>
      <div className="scr-wrap">
        <div
          className={classnames(styles.inner, {
            [styles.scroll]: scrolled,
            [styles.dark_color]: headerDarkColor,
            [styles.white_bg]: headerWhiteBg,
          })}
        >
          <p>
            <Link
              aria-label="Home page"
              className={styles.logo}
              to="/"
              id="link-header-to-home"
            ></Link>
          </p>

          {deviceState && (
            <>
              {deviceState === DESKTOP_KEY ? (
                <>
                  <Menu
                    className={classnames(
                      styles.menu,
                      hideRequestButton ? styles.menu_center : null
                    )}
                  />

                  <div className={styles.buttons}>
                    {!hideRequestButton && (
                      <Link
                        className={classnames(
                          styles.buttons_demo,
                          'scr-btn',
                          'scr-btn--small',
                          {
                            'scr-btn--white-light':
                              !scrolled && !headerDarkColor,
                            'scr-btn--light': scrolled || headerDarkColor,
                          }
                        )}
                        to={header.requestButton.url}
                        id="link-header-to-request"
                      >
                        {header.requestButton.title}
                      </Link>
                    )}
                    <Link
                      to={header.loginButton.url}
                      className={classnames(
                        styles.buttons_login,
                        'scr-btn scr-btn--small',
                        {
                          'scr-btn--white': !scrolled && !requestButtonBlue,
                        }
                      )}
                      id="link-header-to-app-screver-com"
                    >
                      {header.loginButton.title}
                      <Icon name="arrow-right" />
                    </Link>
                  </div>
                </>
              ) : (
                <MobileMenu showRequestDemo={!headerDarkColor} />
              )}
            </>
          )}
        </div>
      </div>
    </header>
  );
};

export default Header;
