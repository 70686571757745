// extracted by mini-css-extract-plugin
export var wrap = "menu-dropdown-module--wrap--1Ddgs";
export var btn = "menu-dropdown-module--btn--2xLYA";
export var open = "menu-dropdown-module--open--3sSCr";
export var list = "menu-dropdown-module--list--2n3l9";
export var soon = "menu-dropdown-module--soon--1IIZZ";
export var groups = "menu-dropdown-module--groups--VO7RG";
export var groupItem = "menu-dropdown-module--groupItem--3dnoO";
export var groupItem_title = "menu-dropdown-module--groupItem_title--2iBfU";
export var pagesList = "menu-dropdown-module--pagesList--9adfA";
export var pagesList_item = "menu-dropdown-module--pagesList_item--1gs19";
export var pagesList_item__textInner = "menu-dropdown-module--pagesList_item__textInner--3bHlY";