import React, { useState } from 'react';
import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import Link from '@/components/Link';
import NavLink from '../Link';

import BurgerButton from 'components/UI/BurgerButton';
import * as styles from './mobile-menu.module.scss';

import useOptions from 'hooks/graphql/useOptions';
import convertToSlug from 'helpers/stringToSlug';

const MobileMenu = ({ showRequestDemo }) => {
  const [open, setOpen] = useState(false);

  const {
    header: {
      header,
      header: { headerNavigation },
    },
  } = useOptions();

  return (
    <>
      <BurgerButton open={open} onClick={(e) => setOpen(!open)} />
      <CSSTransition
        in={open}
        classNames="scr-css-transition--slide-left"
        timeout={{
          enter: 200,
          exit: 200,
        }}
        unmountOnExit
      >
        {/* TODO: old */}
        {/* <nav className={styles.menu}>
          {menus &&
            menus.map((col, index) => {
              return (
                <div className={styles.menu_item} key={`col-${index}`}>
                  {col.path === '#' ? (
                    <>
                      <p className="scr-txt-strong">{col.label}</p>
                      <ul>
                        {col.children.map((li, i) => {
                          return (
                            <li key={`li-${i}`}>
                              <Link
                                to={li.path}
                                className={classnames(styles.listItem, {
                                  [styles.soon]: li.connectedNode == null,
                                })}
                                id={`link-mob-nav-to-${convertToSlug(
                                  li.label
                                )}`}
                              >
                                {li.label}
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  ) : (
                    <Link
                      to={col.path}
                      className="scr-txt-strong"
                      id={`link-mob-nav-to-${convertToSlug(col.label)}`}
                    >
                      {col.label}
                    </Link>
                  )}
                </div>
              );
            })}
          <div className={styles.menu_buttons}>
            {showRequestDemo && (
              <Link
                className="scr-btn scr-btn--small scr-btn--light"
                to={header.requestButton.url}
                id="link-mob-nav-to-request"
              >
                {header.requestButton.title}
              </Link>
            )}
            <Link
              to={header.loginButton.url}
              className={classnames('scr-btn', 'scr-btn--small')}
              id="link-mob-nav-to-app-screver-com"
            >
              {header.loginButton.title}
            </Link>
          </div>
        </nav> */}

        <nav className={styles.menu}>
          {headerNavigation &&
            headerNavigation.map((navItem, idx) => {
              const navItemUrl = process.env.GATSBY_DEV
                ? navItem?.headerNavigationLink?.url.replace(
                    'https://screver.com',
                    ''
                  )
                : navItem?.headerNavigationLink?.url;

              return (
                <div className={styles.menu_item} key={navItemUrl + idx}>
                  {navItem.headerLinkOrDropdownSelect === 'dropdown' ? (
                    navItem.headerNavigationDropdown.map((item, itemIdx) => {
                      return (
                        <div key={item.headerNavigationDropdownTitle + itemIdx}>
                          <p className="scr-txt-strong">
                            {item?.headerNavigationDropdownTitle}
                          </p>

                          <ul>
                            {item.headerNavigationDropdownGroup.map(
                              (group, groupIdx) => {
                                return (
                                  <li
                                    key={
                                      group?.headerNavigationDropdownGroupTitle +
                                      groupIdx
                                    }
                                    style={{
                                      pointerEvents:
                                        group?.headerNavigationDropdownGroupTitle ===
                                          'Pharma and Healthcare' && 'none',
                                    }}
                                  >
                                    <p className={styles.groupName}>
                                      {group.headerNavigationDropdownGroupTitle}
                                    </p>

                                    {group.headerNavigationDropdownGroupLinks.map(
                                      (link, linkIdx) => {
                                        const linkUrl = process.env.GATSBY_DEV
                                          ? link?.headerNavigationDropdownGroupLinksPage?.url.replace(
                                              'https://screver.com',
                                              ''
                                            )
                                          : link
                                              ?.headerNavigationDropdownGroupLinksPage
                                              ?.url;

                                        return (
                                          <span key={linkUrl + linkIdx}>
                                            <NavLink
                                              url={linkUrl}
                                              navLink={link}
                                            ></NavLink>
                                          </span>
                                        );
                                      }
                                    )}
                                  </li>
                                );
                              }
                            )}
                          </ul>
                        </div>
                      );
                    })
                  ) : (
                    <Link
                      to={navItemUrl}
                      id={`link-mob-nav-to-${convertToSlug(
                        navItem?.headerNavigationLink?.title
                      )}`}
                    >
                      {navItem?.headerNavigationLink?.title}
                    </Link>
                  )}
                </div>
              );
            })}

          <div className={styles.menu_buttons}>
            {showRequestDemo && (
              <Link
                className="scr-btn scr-btn--small scr-btn--light"
                to={header.requestButton.url}
                id="link-mob-nav-to-request"
              >
                {header.requestButton.title}
              </Link>
            )}
            <Link
              to={header.loginButton.url}
              className={classnames('scr-btn', 'scr-btn--small')}
              id="link-mob-nav-to-app-screver-com"
            >
              {header.loginButton.title}
            </Link>
          </div>
        </nav>
      </CSSTransition>
    </>
  );
};

export default MobileMenu;
