import React from 'react';
import classnames from 'classnames';

import Link from '@/components/Link';
import Menu from './components/Menu';

import useOptions from 'hooks/graphql/useOptions';

import * as styles from './footer.module.scss';

const Footer = () => {
  const {
    footer: { footer },
  } = useOptions();

  return (
    <footer className={classnames(styles.footer)}>
      <div className="scr-wrap">
        <div className={classnames(styles.inner)}>
          <div className={styles.left}>
            <Link
              aria-label="Home page"
              className={styles.left_logo}
              to="/"
              id="link-footer-to-home"
            ></Link>

            <div
              className={styles.left_text}
              dangerouslySetInnerHTML={{ __html: footer.aboutText }}
            ></div>
          </div>

          <div className={styles.listWrap}>
            <Menu />
          </div>

          <div className={styles.social}>
            <p>Follow Us:</p>
            <ul className={styles.social_list}>
              {footer.socials.map((social, i) => (
                <li key={social.link + i}>
                  <a
                    href={social.link}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="social link"
                    id={`link-footer-to-${social.link}`}
                  >
                    <img
                      src={social.icon.sourceUrl}
                      srcSet={social.icon.srcSet}
                      alt={social.icon.altText}
                      loading="lazy"
                      width="100%"
                      height="100%"
                    />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>

        <div className={classnames(styles.note)}>
          <div className={classnames(styles.note_copy)}>
            <p>{footer.copyrightText}</p>
          </div>

          <p className={classnames(styles.note_text)}>{footer.noteText}</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
