// extracted by mini-css-extract-plugin
export var header = "header-module--header--pUGPM";
export var inner = "header-module--inner--fAS3b";
export var scroll = "header-module--scroll--bWINU";
export var white_bg = "header-module--white_bg--2hTPf";
export var logo = "header-module--logo--2bmrB";
export var dark_color = "header-module--dark_color--2_NUC";
export var menu = "header-module--menu--OlxbB";
export var menu_center = "header-module--menu_center--1Hjnu";
export var buttons = "header-module--buttons--2LEbX";
export var buttons_login = "header-module--buttons_login--1Thby";
export var buttons_demo = "header-module--buttons_demo--I5Z2_";