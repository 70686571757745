import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const ClickOutside = ({ onClick, children }) => {
  const ref = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        onClick();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, onClick]);

  return <div ref={ref}>{children}</div>;
};

ClickOutside.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ClickOutside;
